import styles from './LicensePage.module.scss';
import React from 'react';
import ScrollToTop from '../../../functions/scrollToTop';

function LicensePage() {
  return (
    <div className={styles.licensePage}>
      <ScrollToTop />
      <img src='./images/license/license1.jpg' alt='license1' />
      <img src='./images/license/license2.jpg' alt='license2' />
      <img src='./images/license/license3.jpg' alt='license3' />
    </div>
  );
}

export default LicensePage;
