import React from 'react';
import styles from './SpecialOffers.module.scss';
import SpecialOffersTemplate from './specialOffersTemplate/SpecialOffersTemplate';
import DefaultComponent from '../../../defaultComponent/DefaultComponent';
import variables from '../../../../styles/variables.scss';
import { promotions } from '../../../../clinicData/promotions';

function SpecialOffersContent() {
  return (
    <div className={styles.specialOffers}>
      {promotions.map((item, index) => {
        return (
          <SpecialOffersTemplate
            key={index}
            title={item.title}
            price={item.price}
          />
        );
      })}
    </div>
  );
}

function SpecialOffers() {
  return (
    <DefaultComponent
      title='Акции и спецпредложения'
      content={SpecialOffersContent()}
      color={variables.coral}
      background={variables.peach}
    />
  );
}

export default SpecialOffers;
