import styles from './Reviews.module.scss';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DefaultComponent from '../../../defaultComponent/DefaultComponent';
import variables from '../../../../styles/variables.scss';

function SampleNextArrow(props) {
  const { onClick } = props;
  return <div className={styles.reviews_next_arrow} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return <div className={styles.reviews_prev_arrow} onClick={onClick} />;
}

function Reviews() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow className={styles.reviews_prev_arrow} />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function ReviewsContert() {
    return (
      <Slider className={styles.reviews_slider} {...settings}>
        <div className={styles.reviews_slide}>
          <p>
            Приветливый персонал, высокий профессионализм врача и медсестры.
            Обратилась к терапевту с заболеванием, о котором давно знала
            (синдром wpw), до этого никто так просто и доходчиво не объяснял,
            что это за заболевание и как его вылечить. Меня проконсультировали и
            перенаправили к хирургу-аритмологу в один из стационаров города, где
            меня прооперировали! Спасибо персоналу клиники!
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Познакомился по своей проблеме с Дмитрием Сергеевичем еще 4 года
            назад, он вел тогда прием в клинике в центре города, всегда
            отзывчивый, все доходчиво объясняет, главное — грамотный специалист,
            жаль только приходится ездить с другого конца города, но я готов.
            Также рекомендовал своим знакомым, спасибо! <br /> <br />
            <i>Лечащий врач: Благушин Д. С.</i>
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Замечательный доктор! Была на приеме, в медицинском центре, п.
            Мурино, пришла после походов к уже нескольким специалистам данного
            профиля, врач подошла комплексно к решению моей проблемы, виден
            результат — самое главное. Спасибо Вам, Наталья Александровна!
            <br />
            <br />
            <i>Лечащий врач: Воронова Н. А.</i>
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Сегодня была на приеме у гинеколога Вороновой Натальи Александровны,
            очень внимательная, тактичная Врач очень грамотно и доходчиво все
            обьяснила. Рекомендую
            <br />
            <br />
            <i>Лечащий врач: Воронова Н. А.</i>
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Очень нравится эта клиника. Цены отличные, вежливый, приветливый,
            внимательный персонал. Результаты анализов приходят очень быстро.
            Очередей нет. Ряда, что есть такая клиника рядом с домом! Молодцы!
            <br />
            <br />
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            04.07. Сдавала кровь на 2 анализа! Спасибо большое медсестре
            Наталье! Очень внимательная и всё аккуратно сделала, я даже не
            почувствовала, как она ввела иглу! Надеюсь, что в следующий раз
            опять к ней попаду! 🙏
            <br />
            <br />
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Очень удобно что есть такие небольшие центры рядом с домом. Никуда
            не надо далеко ехать, чтобы получить медицинское обслуживание. Это
            сильно облегчает жизнь. Обращалась за услугой - суточный
            кардиомонитор. Всё хорошо сделали. Сотрудники вежливые. Спасибо.
            <br />
            <br />
          </p>
        </div>
        <div className={styles.reviews_slide}>
          <p>
            Посещаю медцентр уже много раз,сдаю анализы для контроля щитовидной
            железы, делал ЭКГ,устраивает цена и отношение сотрудников, качество
            предоставляемых услуг.
            <br />
            <br />
          </p>
        </div>
      </Slider>
    );
  }

  return (
    <DefaultComponent
      title='Отзывы клиентов'
      color={variables.textGrey}
      background={variables.backroundGrey}
      content={ReviewsContert()}
      hSize='h3'
    />
  );
}

export default Reviews;
