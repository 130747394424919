import styles from './PriceSectionTemplate.module.scss';

import React from 'react';

function PriceSectionTemplate({ title, price }) {
  return (
    <div className={styles.priceSectionTemplate}>
      <div className={styles.priceSectionTemplate_title}>{title}</div>
      <div className={styles.priceSectionTemplate_price}>{price}</div>
    </div>
  );
}

export default PriceSectionTemplate;
