import styles from './DefaultComponent.module.scss';

import React from 'react';

function DefaultComponent({ title, content, color, background, hSize }) {
  return (
    <div className={styles.default} style={{ background: background }}>
      <h2 className={styles.default_title} style={{ color: color }}>
        {title}
      </h2>

      {content}
    </div>
  );
}

export default DefaultComponent;
