export const allPriceItems = [
	{
		id: 0,
		name: 'УЗИ щитовидной железы',
		price: '1200 руб',
		description: '',
	},
	{
		id: 1,
		name: 'Консультация гинеколога-эндокринолога (первичная)',
		price: '1700 руб',
		description: '',
	},
	{
		id: 2,
		name: 'Регистрация и расшифровка ЭКГ',
		price: '950 руб',
		description: '',
	},
	{
		id: 3,
		name: 'Консультация врача уролога- андролога (первичная) ',
		price: '1800  руб',
		description: '',
	},
	{
		id: 4,
		name: 'Консультация врача терапевта (кардиолога, эндокринолога) первичная',
		price: '1600 руб',
		description: '',
	},
	{
		id: 5,
		name: 'Клинический анализ крови',
		price: '600 руб',
		description:
			'С морфологической оценкой патологических результатов, без СОЭ',
	},
	{
		id: 6,
		name: 'Повторная консультация уролога-андролога',
		price: '1600 руб',
		description: '',
	},

	{
		id: 8,
		name: 'Дуплексное сканирование вен нижних конечностей',
		price: '1800 руб',
		description: '',
	},
]
