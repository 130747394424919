import styles from './FeedbackForm.module.scss'
import React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

function FeedbackForm() {
	const [formState, setFormState] = useState({ name: '', phone: '' })
	const [successMessage, setSuccessMessage] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})

	function onSubmit() {
		// sendMessage({ name: formState.name, phone: formState.phone })
		let userData = new FormData()
		userData.set('name', formState.name)
		userData.set('phone', formState.phone)
		fetch('mailer/smart.php', {
			method: 'POST',
			body: userData,
		})
			.then(response => {
				if (response.ok) {
					setFormState({ name: '', phone: '' })
					setSuccessMessage(true)
					return
				}
				setErrorMessage(true)
			})

			.catch(error => {
				console.log(error)
			})
	}

	return (
		<>
			<form
				className={styles.feedbackForm}
				id='form'
				onSubmit={handleSubmit(onSubmit)}
			>
				<span>
					Закажите звонок,
					<br />и мы вам перезвоним
				</span>
				<div className={styles.feedbackForm_wrapper}>
					<input
						className={
							styles.feedbackForm_item + ' ' + styles.feedbackForm_input
						}
						{...register('name', {
							required: true,
							minLength: 2,
							maxLength: 50,
						})}
						type='text'
						placeholder='Ваше имя'
						name='name'
						onChange={e =>
							setFormState({
								...formState,
								[e.target.name]: e.target.value,
							})
						}
						value={formState.name}
					/>
					<input
						className={
							styles.feedbackForm_item + ' ' + styles.feedbackForm_input
						}
						{...register('phone', {
							required: true,
							minLength: 5,
							maxLength: 50,
						})}
						type='number'
						placeholder='Ваш телефон'
						name='phone'
						onChange={e =>
							setFormState({
								...formState,
								[e.target.name]: e.target.value,
							})
						}
						value={formState.phone}
					/>
					<input
						className={styles.feedbackForm_item + ' ' + styles.feedbackForm_btn}
						value='Заказать'
						type='submit'
					/>
				</div>
			</form>
			<div className={styles.feedbackForm_messages}>
				{errors?.name && (
					<p className={styles.feedbackForm_inputError}>Введите ваше имя</p>
				)}
				{errors?.phone && (
					<p className={styles.feedbackForm_inputError}>
						Введите ваш номер телефона
					</p>
				)}
				{successMessage ? (
					<p className={styles.feedbackForm_success}>Ваша заявка отправлена!</p>
				) : null}
				{errorMessage ? (
					<p className={styles.feedbackForm_inputError}>
						Произошла ошибка. Попробуййте позже или позвоните нам.
					</p>
				) : null}
			</div>
		</>
	)
}

export default FeedbackForm
