import styles from './PageNotFound.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../functions/scrollToTop';

function PageNotFound() {
  return (
    <div className={styles.pageNotFound}>
      <ScrollToTop />
      <h1 className={styles.pageNotFound_text}> Страница не найдена </h1>
      <p className={styles.pageNotFound_text}>Перейдите на главную страницу</p>
      <Link to='/' className={styles.pageNotFound_link}>
        На главную
      </Link>
    </div>
  );
}

export default PageNotFound;
