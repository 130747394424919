import styles from './Modal.module.scss'
import React, { useEffect } from 'react'
import { useContext, useState } from 'react'
import { Context } from './Context.js'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

function Modal() {
	const {
		modalState,
		changeModalForm,
		changeModalInfo,
		changeModalWindow,
		changeModalError,
	} = useContext(Context)

	const [formState, setFormState] = useState({
		name: '',
		phone: '',
		visible: true,
	})

	const clearForm = () => {
		setFormState({ name: '', phone: '' })
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})

	useEffect(() => {
		clearForm()
	}, [modalState.modalWindow])

	const handleOnClose = () => {
		changeModalWindow(false)
	}

	function ModalForm() {
		function onSubmit() {
			// sendMessage({ name: formState.name, phone: formState.phone })
			let userData = new FormData()
			userData.set('name', formState.name)
			userData.set('phone', formState.phone)
			fetch('mailer/smart.php', {
				method: 'POST',
				body: userData,
			})
				.then(response => {
					if (response.ok) {
						// setTimeout(() => {
						changeModalForm(false)
						changeModalInfo(true)
						clearForm()
						return
					}
					setTimeout(() => {
						changeModalForm(false)
						changeModalError(true)
					}, 1000)

					setTimeout(() => {
						changeModalError(false)
						changeModalForm(true)
						changeModalWindow(false)
					}, 5000)

					throw new Error('Something went wrong')
				})
				.catch(error => {
					console.log('Произошла ошибка ', error)
				})
		}

		return (
			<>
				<div className={styles.modal_title}>
					Заполните форму,
					<br /> и мы вам перезвоним
				</div>

				<form
					className={styles.modal_form}
					id='form'
					onSubmit={handleSubmit(onSubmit)}
				>
					<input
						{...register('name', {
							required: true,
							minLength: 2,
							maxLength: 50,
						})}
						className={styles.modal_form_input}
						type='text'
						placeholder='Ваше имя'
						name='name'
						onChange={e =>
							setFormState({
								...formState,
								[e.target.name]: e.target.value,
							})
						}
						value={formState.name}
					/>

					{errors?.name && (
						<p className={styles.modal_form_inputError}>Введите ваше имя</p>
					)}

					<input
						{...register('phone', {
							required: true,
							minLength: 5,
							maxLength: 13,
						})}
						className={styles.modal_form_input}
						type='number'
						placeholder='Номер телефона'
						name='phone'
						onChange={e =>
							setFormState({
								...formState,
								[e.target.name]: e.target.value,
							})
						}
						value={formState.phone}
					/>
					{errors?.phone && (
						<p className={styles.modal_form_inputError}>
							Введите ваш номер телефона
						</p>
					)}

					<input
						className={styles.modal_form_submit}
						value='Заказать звонок'
						type='submit'
					/>
				</form>

				<span className={styles.modal_policyText}>
					Нажимая кнопку «Заказать», вы подтверждаете согласие на{' '}
					<Link onClick={() => changeModalWindow(false)} to='/privacy-policy'>
						обработку персональных данных
					</Link>
				</span>
			</>
		)
	}

	function ModalWindow() {
		return (
			<div onClick={handleOnClose} className={styles.modal}>
				<div onClick={e => e.stopPropagation()} className={styles.modal_body}>
					<div onClick={handleOnClose} className={styles.modal_close}></div>
					{modalState.modalError && (
						<h3 className={styles.modal_info}>
							Произошла ошибка сети.Попробуйте позже или позвоните нам.
						</h3>
					)}
					{modalState.modalForm && ModalForm()}
					{modalState.modalInfo && (
						<h3 className={styles.modal_info}>
							Спасибо, Ваша заявка отправлена. В ближайшее время с вами свяжется
							наш администратор.
						</h3>
					)}
				</div>
			</div>
		)
	}

	return modalState.modalWindow ? ModalWindow() : null
}

export default Modal
