import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../functions/scrollToTop';
function PrivacyPolicy() {
  return (
    <div style={{ marginTop: '150px', marginBottom: '150px' }}>
      <ScrollToTop />
      <strong>Соглашение об использовании сайта</strong>
      <br />
      <br />
      Настоящее Соглашение об использовании интернет-сайта https://disamed.ru/
      (далее – Соглашение) определяет условия использования материалов и
      сервисов, размещенных на интернет-сайте (далее – Сайт) по адресу:
      https://disamed.ru/, посетителями и пользователями Сайта (далее –
      Пользователи). <br />
      1. Общие условия.
      <br /> 1.1. Сайт создан в целях предоставления Пользователям Сайта
      информации об условиях и правилах оказания медицинских услуг. <br /> 1.2.
      Использование материалов и сервисов Сайта регулируется нормами
      действующего законодательства Российской Федерации. <br />
      1.3. Для получения информации об условиях и правилах оказания медицинских
      услуг Пользователю необходимо выполнить следующие действия: 1.3.1.
      заполнить установленную форму на Сайте, указав ФИО (фамилия имя отчество)
      и контактный номер телефона Пользователя. <br /> 1.3.2. Пользователь
      соглашается с предоставлением данных, указанных в п.1.3.1. настоящего
      Соглашения, нажатием кнопки «Заказать звонок». <br /> 1.4. Получая доступ
      к материалам Сайта, Пользователь считается присоединившимся к настоящему
      Соглашению. 1. Обязательства Пользователя. <br />
      2.1. Использование материалов Сайта без согласия правообладателей не
      допускается. <br /> 2.2. При цитировании материалов Сайта, включая
      охраняемые авторские произведения, ссылка на Сайт обязательна. <br /> 2.3.
      Администрация Сайта не несет ответственности за посещение и использование
      им внешних ресурсов, ссылки на которые могут содержаться на Сайте. <br />{' '}
      2.4. Администрация Сайта не несет ответственности и не имеет прямых или
      косвенных обязательств перед Пользователем в связи с любыми возможными или
      возникшими потерями или убытками, связанными с любым содержанием Сайта,
      регистрацией авторских прав и сведениями о такой регистрации, товарами или
      услугами, доступными на или полученными через внешние сайты или ресурсы
      либо иные контакты Пользователя, в которые он вступил, используя
      размещенную на Сайте информацию или ссылки на внешние ресурсы. <br /> 2.5.
      Пользователь согласен с тем, что Администрация Сайта не несет какой-либо
      ответственности и не имеет каких-либо обязательств в связи с рекламой,
      которая может быть размещена на Сайте. <br />
      3. Прочие условия. <br /> 3.1. Все возможные споры, вытекающие из
      настоящего Соглашения или связанные с ним, подлежат разрешению в
      соответствии с действующим законодательством Российской Федерации. <br />{' '}
      3.2. Признание судом какого-либо положения Соглашения недействительным или
      не подлежащим принудительному исполнению не влечет недействительности иных
      положений Соглашения. <br /> 3.3. Бездействие со стороны Администрации
      Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не
      лишает Администрацию Сайта права предпринять позднее соответствующие
      действия в защиту своих интересов и защиту авторских прав на охраняемые в
      соответствии с законодательством материалы Сайта. <br />
      3.4. Администрация Сайта вправе в любое время в одностороннем порядке
      изменять условия настоящего Соглашения. Такие изменения вступают в силу с
      момента размещения новой версии Соглашения на сайте. При несогласии
      Пользователя с внесенными изменениями он обязан отказаться от доступа к
      Сайту, прекратить использование материалов и сервисов Сайта. <br /> 3.5.
      Переходя на Сайт по адресу: https://disamed.ru/, Пользователь
      подтверждает, что принимает условия настоящего Соглашения, а также
      Политики конфиденциальности Сайта, являющейся неотъемлемой частью
      настоящего Соглашения и размещенной на странице по адресу:
      https://disamed.ru/.
      <br />
      <br />
      <br />
      <strong>Политика конфиденциальности</strong>
      <br />
      <br />
      Настоящая Политика конфиденциальности персональных данных (далее –
      Политика) действует в отношении всей информации, размещенной на
      интернет-сайте https://disamed.ru/ (далее – Сайт), которую могут получить
      посетители Сайта (далее – Пользователи) во время использования сайта, его
      сервисов, программ и продуктов. Использование сервисов Сайта означает
      безоговорочное согласие Пользователя с настоящей Политикой и указанными в
      ней условиями обработки персональной информации. В случае несогласия с
      условиями Политики Пользователь должен воздержаться от использования
      сервисов Сайта.
      <br />
      1. Общие положения. <br />
      1.1. В рамках настоящей Политики под персональной информацией Пользователя
      понимаются: 1.1.1. Персональная информация, которую Пользователь
      предоставляет о себе самостоятельно в процессе использования Сервисов,
      включая персональные данные Пользователя, а именно фамилию, имя, отчество
      (ФИО) Пользователя и номер мобильного телефона Пользователя. <br />
      1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе
      их использования с помощью установленного на устройстве Пользователя
      программного обеспечения, в том числе IP-адрес, данные файлов cookie,
      информация о браузере Пользователя (или иной программе, с помощью которой
      осуществляется доступ к сервисам), технические характеристики оборудования
      и программного обеспечения, используемых Пользователем, дата и время
      доступа к сервисам, адреса запрашиваемых страниц и иная подобная
      информация. <br /> 1.1.3. Настоящая Политика конфиденциальности
      применяется только к Сайту https://disamed.ru/. Сайт https://disamed.ru/
      не контролирует и не несет ответственности за сайты третьих лиц, на
      которые Пользователь может перейти по ссылкам, доступным на Сайте
      https://disamed.ru/.
      <br />
      2. Цели обработки персональной информации пользователей. <br />
      2.1. Сайт собирает и хранит только ту персональную информацию, которая
      необходима для предоставления сервисов или исполнения соглашений и
      договоров с Пользователем, за исключением случаев, когда законодательством
      предусмотрено обязательное хранение персональной информации в течение
      определенного законом срока. <br /> 2.2. Персональную информацию
      Пользователя Сайт обрабатывает в следующих целях: <br /> 2.2.1.
      Идентификации Пользователя, оставившего заявку на Сайте, для
      предоставления Пользователю информации об оказании медицинских услуг.{' '}
      <br /> 2.2.2. Предоставления Пользователю доступа к персонализированным
      ресурсам Сайта. <br />
      2.2.3. Установления с Пользователем обратной связи, включая обработку
      заявок и запросов, касающихся предоставления Пользователю информации об
      оказании медицинских услуг. <br /> 2.2.4. Определения места нахождения
      Пользователя для обеспечения безопасности, предотвращения мошенничества.{' '}
      <br />
      2.2.5. Подтверждения достоверности и полноты персональных данных,
      предоставленных Пользователем. <br /> 2.2.6. Предоставления Пользователю
      эффективной клиентской и технической поддержки при возникновении проблем,
      связанных с использованием Сайта. <br />
      2.2.7. Осуществления рекламной деятельности с согласия Пользователя.
      <br />
      3. Условия обработки персональной информации пользователей и ее передача
      третьим лицам. <br /> 3.1. Сайт хранит персональную информацию
      Пользователей в соответствии с внутренними регламентами конкретных
      сервисов. <br />
      3.2. В отношении персональной информации Пользователя сохраняется ее
      конфиденциальность, кроме случаев добровольного предоставления
      Пользователем информации о себе для общего доступа неограниченному кругу
      лиц. При использовании отдельных сервисов Пользователь соглашается с тем,
      что определенная часть его персональной информации становится
      общедоступной. <br /> 3.3. Сайт вправе передать персональную информацию
      Пользователя третьим лицам в следующих случаях: <br />
      3.3.1. Пользователь выразил согласие на такие действия. <br /> 3.3.2.
      Передача необходима для использования Пользователем определенного сервиса
      либо для исполнения определенного соглашения или договора с Пользователем.{' '}
      <br /> 3.3.4. Передача предусмотрена российским или иным применимым
      законодательством в рамках установленной законодательством процедуры.{' '}
      <br /> 3.3.5. В случае продажи Сайта к приобретателю переходят все
      обязательства по соблюдению условий настоящей Политики применительно к
      полученной им персональной информации. 3.4. Обработка персональных данных
      Пользователя осуществляется без ограничения срока любым законным способом,
      в том числе в информационных системах персональных данных с использованием
      средств автоматизации или без использования таких средств. Обработка
      персональных данных Пользователей осуществляется в соответствии с
      Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных". <br />{' '}
      3.5. При утрате или разглашении персональных данных Администрация Сайта
      информирует Пользователя об утрате или разглашении персональных данных.{' '}
      <br /> 3.6. Администрация Сайта принимает необходимые организационные и
      технические меры для защиты персональной информации Пользователя от
      неправомерного или случайного доступа, уничтожения, изменения,
      блокирования, копирования, распространения, а также от иных неправомерных
      действий третьих лиц. <br />
      3.7. Администрация Сайта совместно с Пользователем принимает все
      необходимые меры по предотвращению убытков или иных отрицательных
      последствий, вызванных утратой или разглашением персональных данных
      Пользователя.
      <br />
      4. Обязательства сторон. <br /> 4.1. Пользователь обязан: <br /> 4.1.1.
      Предоставить информацию о персональных данных, необходимую для пользования
      Сайтом.
      <br />
      4.1.2. Обновлять, дополнять предоставленную информацию о персональных
      данных в случае изменения данной информации. <br /> 4.2. Администрация
      Сайта обязана: <br /> 4.2.1. Использовать полученную информацию
      исключительно для целей, указанных в настоящей Политике
      конфиденциальности. <br />
      4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
      разглашать без предварительного письменного разрешения Пользователя, а
      также не осуществлять продажу, обмен, опубликование либо разглашение иными
      возможными способами переданных персональных данных Пользователя, за
      исключением предусмотренных настоящей Политикой конфиденциальности. <br />{' '}
      4.2.3. Принимать меры предосторожности для защиты конфиденциальности
      персональных данных Пользователя согласно порядку, обычно используемому
      для защиты такого рода информации в существующем деловом обороте. <br />{' '}
      4.2.4. Осуществить блокирование персональных данных, относящихся к
      соответствующему Пользователю, с момента обращения или запроса
      Пользователя или его законного представителя либо уполномоченного органа
      по защите прав субъектов персональных данных на период проверки в случае
      выявления недостоверных персональных данных или неправомерных действий.
      <br />
      5. Ответственность сторон. <br /> 5.1. Администрация Сайта, не исполнившая
      свои обязательства, несет ответственность за убытки, понесенные
      Пользователем в связи с неправомерным использованием персональных данных в
      соответствии с законодательством Российской Федерации. <br /> 5.2. В
      случае утраты или разглашения конфиденциальной информации Администрация
      Сайта не несет ответственности, если данная конфиденциальная информация:{' '}
      <br /> 5.2.1. Стала публичным достоянием до ее утраты или разглашения.{' '}
      <br />
      5.2.2. Была получена от третьей стороны до момента ее получения
      Администрацией Сайта. <br /> 5.2.3. Была разглашена с согласия
      Пользователя.
      <br />
      6. Разрешение споров. <br /> 6.1. До обращения в суд с иском по спорам,
      возникающим из отношений между Пользователем Сайта и Администрацией Сайта,
      обязательным является предъявление претензии (письменного предложения о
      добровольном урегулировании спора). <br /> 6.2. Получатель претензии в
      течение 10 календарных дней со дня получения претензии письменно
      уведомляет заявителя претензии о результатах рассмотрения претензии.{' '}
      <br /> 6.3. При недостижении соглашения спор будет передан на рассмотрение
      в суд в соответствии с действующим законодательством Российской Федерации.{' '}
      <br /> 6.4. К настоящей Политике конфиденциальности и отношениям между
      Пользователем и Администрацией Сайта применяется действующее
      законодательство Российской Федерации.
      <br />
      7. Дополнительные условия. <br /> 7.1. Администрация Сайта вправе вносить
      изменения в настоящую Политику конфиденциальности без согласия
      Пользователя. <br /> 7.2. Новая Политика конфиденциальности вступает в
      силу с момента ее размещения на Сайте, если иное не предусмотрено новой
      редакцией Политики конфиденциальности. <br /> 7.3. Действующая Политика
      конфиденциальности размещена на странице по адресу: https://disamed.ru/.{' '}
      <br /> 7.4. Настоящая Политика конфиденциальности является неотъемлемой
      частью Соглашения об использовании Сайта, размещенного на странице по
      адресу: https://disamed.ru/.
      <br />
      <br />
      <br />
      <Link className={styles.backLink} to={'/'}>
        На главную
      </Link>
    </div>
  );
}

export default PrivacyPolicy;
