import styles from './OnlineEntryTemplate.module.scss';

import React from 'react';
import { useContext } from 'react';
import { Context } from '../../../../modal/Context';

export default function OnlineEntryTemplate() {
  const { changeModalWindow } = useContext(Context);
  return (
    <div
      onClick={() => changeModalWindow(true)}
      className={styles.onlineEntryTemplate}
    >
      <h2 className={styles.onlineEntryTemplate_title}>Онлайн запись</h2>
    </div>
  );
}
