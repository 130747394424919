import styles from './Directions.module.scss';
import React from 'react';
import DirectionTemplate from './directionsTemplates/DirectionTemplate';
import OnlineEntryTemplate from './onlineEntryTemplate/OnlineEntryTemplate';
import { Link } from 'react-router-dom';
import DefaultComponent from '../../../defaultComponent/DefaultComponent';
import variables from '../../../../styles/variables.scss';

function DirectionsContent() {
  return (
    <div id='directions' className={styles.directions}>
      <Link className={styles.directions_link} to={'/areas-of-work/analyzes'}>
        <DirectionTemplate title='Анализы' id='analyzes' />
      </Link>
      <Link to={'/areas-of-work/gynecology'}>
        <DirectionTemplate title='Гинекология' id='gynecology' />
      </Link>
      <Link to={'/areas-of-work/urology'}>
        <DirectionTemplate title='Урология' id='urology' />
      </Link>
      <Link to={'/areas-of-work/therapy'}>
        <DirectionTemplate title='Терапия' id='therapy' />
      </Link>
      <Link to={'/areas-of-work/ultrasound-diagnostic'}>
        <DirectionTemplate title='УЗ-диагностика' id='ultrasound-diagnostic' />
      </Link>

      <OnlineEntryTemplate />
    </div>
  );
}

function Directions() {
  return (
    <DefaultComponent
      title='Направления'
      content={DirectionsContent()}
      color={variables.mainColor}
    />
  );
}

export default Directions;
