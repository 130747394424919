import styles from './PriceSection.module.scss';
import PriceSectionTemplate from './priceSectionTemplate/PriceSectionTemplate';
import React from 'react';
import { allPriceItems } from '../../clinicData/allPriceItems';
import { Link } from 'react-router-dom';
import DefaultComponent from '../defaultComponent/DefaultComponent';
import variables from '../../styles/variables.scss';

function PriceSectionContent() {
  return (
    <div className={styles.priceSection}>
      <div className={styles.priceSection_items}>
        {allPriceItems.map((item, i) => {
          if (i < 8) {
            return (
              <PriceSectionTemplate
                key={item.id}
                title={item.name}
                price={item.price}
              />
            );
          }
        })}
      </div>

      <Link to='/price' className={styles.priceSection_link}>
        Посмотреть все цены
      </Link>
    </div>
  );
}

function PriceSection() {
  return (
    <DefaultComponent
      title='Цены и услуги'
      content={PriceSectionContent()}
      color={variables.mainColor}
      background={variables.aquamarine}
    />
  );
}

export default PriceSection;
