import React from 'react';
import Promo from './promo/Promo';
import Directions from './directions/Directions';
import SpecialOffers from './specialOffers/SpecialOffers';
import PriceSection from '../../priceSection/PriceSection';
import Instructions from './instructions/Instructions';
import FeedbackForm from '../../feedbackForm/FeedbackForm';
import Reviews from './reviews/Reviews';
import ScrollToTop from '../../../functions/scrollToTop';

function Main() {
  return (
    <>
      <ScrollToTop />
      <Promo />
      <Directions />
      <SpecialOffers />
      <PriceSection />
      <Instructions />
      <FeedbackForm />
      <Reviews />
    </>
  );
}

export default Main;
