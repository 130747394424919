import styles from './Header.module.scss';
import { logo } from '../../images/logo/logo';
import { Link } from 'react-router-dom';

import React from 'react';
import { useState } from 'react';

function Header() {
  const [burgerMenu, setBurgerMenu] = useState(false);

  function toggleBurgerMenu() {
    setBurgerMenu(!burgerMenu);
  }

  return (
    <header className={styles.header}>
      <a alt='logo-disamed' className={styles.header_logo} href='/'>
        {logo}
        <div className={styles.header_logo_phone}>+7 (965) 814-20-10</div>
      </a>
      <div onClick={() => toggleBurgerMenu()} className={styles.header_menuBtn}>
        <span></span>
      </div>
      <div className={styles.header_wrapper}>
        <ul className={styles.header_nav}>
          <Link to='/' className={styles.header_nav_item}>
            Главная
          </Link>
          <li
            className={
              styles.header_nav_item + ' ' + styles.header_nav_dropdown
            }
          >
            Направления
            <ul className={styles.header_nav_dropdown_list}>
              <a
                href='/#directions'
                className={styles.header_nav_dropdown_item}
              >
                Направления
              </a>
              <Link
                to={`/areas-of-work/urology`}
                className={styles.header_nav_dropdown_item}
              >
                Урология
              </Link>
              <Link
                to={`/areas-of-work/gynecology`}
                className={styles.header_nav_dropdown_item}
              >
                Гинекология
              </Link>
              <Link
                to={`/areas-of-work/therapy`}
                className={styles.header_nav_dropdown_item}
              >
                Терапия
              </Link>
              <Link
                to={`/areas-of-work/ultrasound-diagnostic`}
                className={styles.header_nav_dropdown_item}
              >
                УЗИ
              </Link>
              <Link
                to={`/areas-of-work/analyzes`}
                className={styles.header_nav_dropdown_item}
              >
                Анализы
              </Link>
            </ul>
          </li>
          <Link to='/specialists' className={styles.header_nav_item}>
            Специалисты
          </Link>

          <Link to='/price' className={styles.header_nav_item}>
            Цены и услуги
          </Link>
          <Link to='/promotions' className={styles.header_nav_item}>
            Акции
          </Link>
        </ul>
        <div className={styles.header_phone}>
          <a href='tel:+79658142010'>+7 (965) 814-20-10</a>
        </div>
      </div>

      {burgerMenu ? (
        <div className={styles.header_burgerMenu}>
          <div className={styles.header_burgerMenu_directions}>
            <Link
              onClick={() => toggleBurgerMenu()}
              to={`/areas-of-work/urology`}
            >
              Урология
            </Link>
            <Link
              onClick={() => toggleBurgerMenu()}
              to={`/areas-of-work/gynecology`}
            >
              Гинекология
            </Link>
            <Link
              onClick={() => toggleBurgerMenu()}
              to={`/areas-of-work/therapy`}
            >
              Терапия
            </Link>
            <Link
              onClick={() => toggleBurgerMenu()}
              to={`/areas-of-work/ultrasound-diagnostic`}
            >
              УЗИ
            </Link>
            <Link
              onClick={() => toggleBurgerMenu()}
              to={`/areas-of-work/analyzes`}
            >
              Анализы
            </Link>
          </div>
          <div className={styles.header_burgerMenu_nav}>
            <Link onClick={() => toggleBurgerMenu()} to='/'>
              Главная
            </Link>{' '}
            <Link onClick={() => toggleBurgerMenu()} to='/specialists'>
              Специалисты
            </Link>
            <Link onClick={() => toggleBurgerMenu()} to='/price'>
              Цены и услуги
            </Link>
            <Link onClick={() => toggleBurgerMenu()} to='/promotions'>
              Акции
            </Link>
          </div>
          <div className={styles.header_burgerMenu_phone}>
            <a onClick={() => toggleBurgerMenu()} href='tel:+79658142010'>
              +7 (965) 814-20-10
            </a>
          </div>
        </div>
      ) : null}
    </header>
  );
}

export default Header;
