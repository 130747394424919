import styles from './Instructions.module.scss';
import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Instructions() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className={styles.instructions}>
      <div className={styles.instructions_diagram}>
        <div
          className={
            styles.instructions_diagram_item + ' ' + styles.instructions_first
          }
        >
          Оставьте заявку на звонок или позвоните нам
        </div>
        <div
          className={
            styles.instructions_diagram_item + ' ' + styles.instructions_second
          }
        >
          Мы ответим на все вопросы и подберем удобное время
        </div>
        <div
          className={
            styles.instructions_diagram_item + ' ' + styles.instructions_third
          }
        >
          Врач проконсультирует, обследует и предложит возможные варианты
          лечения
        </div>
      </div>
      <div className={styles.instructions_slider_wrapper}>
        <Slider className={styles.instructions_slick_slider} {...settings}>
          <img
            className={styles.instructions_slick_slide}
            src='./images/cabinetUZI.webp'
            alt='ultrasuond'
          />

          <img
            className={styles.instructions_slick_slide}
            src='./images/cabinetginecologa.webp'
            alt='doctor-working'
          />

          <img
            className={styles.instructions_slick_slide}
            src='./images/ECG.webp'
            alt='laboratory'
          />
          <img
            className={styles.instructions_slick_slide}
            src='./images/procedurniycabinet.webp'
            alt='laboratory'
          />
          <img
            className={styles.instructions_slick_slide}
            src='./images/procedurnayamebel.webp'
            alt='laboratory'
          />
          <img
            className={styles.instructions_slick_slide}
            src='./images/UZImurino.webp'
            alt='laboratory'
          />
        </Slider>
      </div>
    </div>
  );
}

export default Instructions;
