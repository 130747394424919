export const promotions = [
	{
		title: 'Мазок ПЦР на коронавирус',
		price: '1400 рублей + 250 рублей забор',
		description: '',
	},
	{
		title: 'Холтровское мониторирование 12-ти канальными отведениями ',
		price: '2700 рублей',
		description: '',
	},
	{
		title: 'Урогенитальный мазок на скрытые инфекции методом ПЦР',
		price: '605 рублей',
		description:
			'В комплекс входит: HK Chlamydia trachomatis/Ureaplasma spp./M.hominis/M.genitalium',
	},
	{
		title: 'Регистрация и расшифровка ЭКГ',
		price: '850 рублей',
		description: '',
	},
	{
		title: 'Консультация врача уролога- андролога (первичная)',
		price: '1600 рублей',
		description: '',
	},
]
