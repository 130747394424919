import styles from './Promo.module.scss';
import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Promo() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dotsClass: `${styles.promo_slider_dots}`,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <div className={styles.promo}>
      <Slider className={styles.promo_slider} {...settings}>
        <div className={styles.promo_slide}>
          <h3>Комплексный подход к здоровью!</h3>
        </div>
        <div className={styles.promo_slide}>
          <h3>Анализы от А до Я!</h3>
        </div>
        <div className={styles.promo_slide}>
          <h3>Ультрасовременные подходы к лечению!</h3>
        </div>
      </Slider>

      <img
        className={styles.promo_img}
        src='./images/medcentrmurino.webp'
        alt='doctor'
      />
    </div>
  );
}

export default Promo;
