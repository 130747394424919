import styles from './App.scss'
import Main from './components/pages/mainPage/Main'

import { Routes, Route } from 'react-router-dom'
import Layout from './components/layout/Layout'
import PageNotFound from './components/pages/pageNotFound/PageNotFound'
import Modal from './components/modal/Modal'
import { Context } from './components/modal/Context'
import { useState } from 'react'
import { lazy, Suspense } from 'react'
import IconsSelector from './images/icons/IconsSelector'
import LicensePage from './components/pages/licensePage/LicensePage'
import PrivacyPolicy from './components/pages/privacyPolicy/PrivacyPolicy'

const Specialists = lazy(() =>
	import('./components/pages/specialistsPage/Specialists')
)
const AbouteSpecialist = lazy(() =>
	import('./components/pages/specialistsPage/AbouteSpecialist/AbouteSpecialist')
)
const Promotions = lazy(() =>
	import('./components/pages/promotionsPage/Promotions')
)
const Price = lazy(() => import('./components/pages/pricePage/Price'))
const AreasOfWork = lazy(() =>
	import('./components/pages/areasOfWorkPage/AreasOfWork')
)
function App() {
	// Стейт модального окна и функция изменения стейта. Передаются везде через контекст.
	const [modalState, setModalState] = useState({
		modalWindow: false,
		modalForm: true,
		modalInfo: false,
		modalError: false,
	})

	// Функции изменения стейта для показа модального окна и его частей: форма, положительный результат, ошибка.
	function changeModalForm(props) {
		setModalState({ ...modalState, modalForm: props })
	}
	function changeModalInfo(props) {
		setModalState({ ...modalState, modalForm: false, modalInfo: props })
	}
	function changeModalWindow(props) {
		setModalState({
			...modalState,
			modalForm: true,
			modalInfo: false,
			modalWindow: props,
		})
	}
	function changeModalError(props) {
		setModalState({ ...modalState, modalForm: false, modalError: props })
	}
	function Loading() {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100vw',

					height: '100vh',
				}}
			>
				<IconsSelector id='loading' />
			</div>
		)
	}

	return (
		<Context.Provider
			value={{
				modalState,
				changeModalForm,
				changeModalInfo,
				changeModalWindow,
				changeModalError,
			}}
		>
			<div className={styles.app}>
				<Suspense fallback={Loading()}>
					<Routes>
						<Route path='/' element={<Layout />}>
							<Route index element={<Main />} />
							<Route path='specialists' element={<Specialists />} />
							<Route path='specialists/:id' element={<AbouteSpecialist />} />
							<Route path='promotions' element={<Promotions />} />
							<Route path='price' element={<Price />} />
							<Route
								path='areas-of-work/:direction'
								element={<AreasOfWork />}
							/>

							<Route path='license' element={<LicensePage />} />
							<Route path='privacy-policy' element={<PrivacyPolicy />} />
							<Route path='*' element={<PageNotFound />} />
						</Route>
					</Routes>
				</Suspense>

				<Modal />
			</div>
		</Context.Provider>
	)
}

export default App
