import styles from './Footer.module.scss'
import IconsSelector from '../../images/icons/IconsSelector'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
	return (
		<footer className={styles.footer}>
			<div className={styles.footer_contacts}>
				<div className={styles.footer_contacts_info}>
					<a className={styles.footer_contacts_phone} href='tel:+79658142010'>
						+7 (965) 814-20-10
					</a>
					<div className={styles.footer_contacts_working_time}>
						<p>пн-пт: 8:00 -20:00</p>
						<p>сб-вс: 8:30-17:00</p>
					</div>
					<div className={styles.footer_contacts_adress}>
						<p>м. Девяткино, мкр. Западное Мурино, п. Мурино, </p>
						<p>ул. Шувалова, 16/9</p>
					</div>
				</div>
				<div className={styles.footer_contacts_social}>
					<a
						href='https://vk.com/disamed'
						target='_blank'
						rel='noopener noreferrer'
					>
						<IconsSelector id='vk' />
					</a>
				</div>
			</div>
			<div className={styles.footer_policy}>
				<div className={styles.footer_policy_documents}>
					<Link to={'/license'}>Лицензии</Link>
				</div>
				<p>© 2022 Северо-Западный Центр доказательной медицины</p>
			</div>
			<div className={styles.footer_map}>
				<YMaps>
					<Map
						className={styles.yandex_map}
						state={{
							center: [60.056853, 30.429327],
							zoom: 16,
						}}
					>
						<Placemark defaultGeometry={[60.056853, 30.429327]} />
					</Map>
				</YMaps>
			</div>
		</footer>
	)
}

export default Footer
