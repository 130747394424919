import styles from './SpecialOffersTemplate.module.scss';

import React from 'react';
import { useContext } from 'react';
import { Context } from '../../../../modal/Context';

function SpecialOffersTemplate({ title, price }) {
  const { changeModalWindow } = useContext(Context); // контекст для открытия модалльного окна
  return (
    <div className={styles.specialOffers_template}>
      <div className={styles.specialOffers_template_wrapper}>
        <h3 className={styles.specialOffers_template_title}>{title}</h3>
        <div className={styles.specialOffers_template_price}>{price}</div>
      </div>

      <button
        onClick={() => changeModalWindow(true)}
        className={styles.specialOffers_template_btn}
      >
        Записаться
      </button>
    </div>
  );
}

export default SpecialOffersTemplate;
