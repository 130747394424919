import styles from './DirectionTemplate.module.scss';
import IconsSelector from '../../../../../images/icons/IconsSelector';
import React from 'react';

function DirectionTemplate({ id, title }) {
  return (
    <div className={styles.directionTemplate}>
      <h3 className={styles.directionTemplate_title}>{title}</h3>
      <div className={styles.directionTemplate_wrapper}>
        <IconsSelector id={id} />
      </div>
    </div>
  );
}

export default DirectionTemplate;
